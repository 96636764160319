<template>
    <div style="width: 100%;height: 100%">
        <div class="index">
            <div class="header-bottom">
                <div class="max-width">
                    <!-- 导航左边 -->
                    <div>
                        <p class="logo-zh">百信宝</p>
                    </div>
                    <!-- 导航右边 -->
                    <ul class="nav">
                        <li class="nav-box" @click="goAnchor('#bg2')">应用场景</li>
                        <li class="nav-box" @click="goAnchor('#bg3')">产业优势</li>
                        <li class="nav-box" @click="goAnchor('#bg3')">应用下载</li>
                        <li class="nav-box">联系我们</li>
                    </ul>
                </div>
            </div>
        </div>
        <img id="bg2" src="../assets/index-bg2.png" style="width: 100%" alt/>
        <img id="bg3" src="../assets/index-bg3.png" style="width: 100%" alt/>
    </div>
</template>

<script>
    export default {
        name: "HomeView",
        methods: {
            //锚点跳转
            goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
                document.querySelector(selector).scrollIntoView({
                    behavior: "smooth"
                });
            },
        }
    }
</script>

<style scoped>
    .index {
        background: url("../assets/index-bg1.png");
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
    }

    .header-bottom {
        margin: 0 auto;
        color: #fff;
        background: #00000000;
        position: relative;
        z-index: 11
    }

    .max-width {
        width: 85%;
        margin: 0 auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center
    }

    .logo-zh {
        font-size: 26px;
        font-weight: 700;
        color: #1A1A1A;
        letter-spacing: 8px
    }

    .nav {
        display: -ms-flexbox;
        display: flex;
        position: relative
    }

    .nav > li {
        width: 180px;
        text-align: center;
        height: 184px;
        line-height: 184px;
        cursor: pointer;
        display: block;
        box-sizing: border-box;
        color: #1A1A1A;
        font-weight: bold;
        font-size: 25px;
        text-decoration: none
    }

</style>